import React from 'react'
import { graphql, Link } from 'gatsby'
import SEO from '../components/seo'
import * as L from '../components/SensorsEquipments/styled'
import * as S from '../components/Content/styled'
import useTranslations from '../components/useTranslations'
import DocumentDownload from '../components/LandingPage/documentDownload'

const Post = props => {
  const post = props.data.markdownRemark
  const { back } = useTranslations()

  return (
    <>

      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        image={post.frontmatter.imageURL}
        video={post.frontmatter.videoURL}
        ogType={post.frontmatter.ogType || 'Article'}
      />
      <Link to="/blog"> &larr; {back}</Link>
      {/* {post.frontmatter.image ? (
        <L.BlogImage
          fluid={post.frontmatter.image.childImageSharp.fluid}
          alt={post.frontmatter.title}
        />
      ) : (
          <L.BlogImageURL
            src={post.frontmatter.imageURL}
            alt={post.frontmatter.title}
          />
        )} */}
      <S.Content>
        <L.Header>{post.frontmatter.title}</L.Header>
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
      </S.Content>
    </>
  )
}

export const query = graphql`
  query Post($locale: String!, $title: String!) {
    markdownRemark(
      frontmatter: { title: { eq: $title } }
      fields: { locale: { eq: $locale } }
    ) {
      frontmatter {
        title
        description
        ogType
        imageURL
        videoURL
        page
      }
      html
      id
      excerpt(pruneLength: 150)
    }
  }
`

export default Post
